export const FETCH_PAYMENT_REQUEST = "FETCH_PAYMENT_REQUEST";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILURE = "FETCH_PAYMENT_FAILURE";

export const GET_COMPANY_PAYMENT = "GET_COMPANY_PAYMENT";

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const COMPANY_CALENDAR_REQUEST = "COMPANY_CALENDAR_REQUEST";
export const COMPANY_CALENDAR_SUCCESS = "COMPANY_CALENDAR_SUCCESS";
export const COMPANY_CALENDAR_FAILURE = "COMPANY_CALENDAR_FAILURE";

// eva nepal
export const FETCH_EVA_MEMBER_REQUEST = "FETCH_EVA_MEMBER_REQUEST";
export const FETCH_EVA_MEMBER_SUCCESS = "FETCH_EVA_MEMBER_SUCCESS";
export const FETCH_EVA_MEMBER_FAILURE = "FETCH_EVA_MEMBER_FAILURE";

// billing company
export const FETCH_BILLING_COMPANY_REQUEST = "FETCH_BILLING_COMPANY_REQUEST";
export const ADD_BILLING_COMPANY_REQUEST = "ADD_BILLING_COMPANY_REQUEST";

export const FETCH_BILLING_COMPANY_SUCCESS = "FETCH_BILLING_COMPANY_SUCCESS";
export const ADD_BILLING_COMPANY_SUCCESS = "ADD_BILLING_COMPANY_SUCCESS";

export const FETCH_BILLING_COMPANY_FAILURE = "FETCH_BILLING_COMPANY_FAILURE";
export const ADD_BILLING_COMPANY_FAILURE = "ADD_BILLING_COMPANY_FAILURE";

// cater data
export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const FETCH_COMPANY_COUNT_SUCCESS = "FETCH_COMPANY_COUNT_SUCCESS";

// cater data
export const FETCH_CATER_USER_REQUEST = "FETCH_CATER_USER_REQUEST";
export const FETCH_CATER_USER_SUCCESS = "FETCH_CATER_USER_SUCCESS";
export const FETCH_CATER_USER_FAILURE = "FETCH_CATER_USER_FAILURE";
export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";

// admin users
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_LOGOUT = "FETCH_USER_LOGOUT";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FETCH_WINLOSS_REQUEST = "FETCH_WINLOSS_REQUEST";
export const FETCH_WINLOSS_SUCCESS = "FETCH_WINLOSS_SUCCESS";
export const FETCH_WINLOSS_FAILURE = "FETCH_WINLOSS_FAILURE";
export const UPDATE_WIN_LOSS = "UPDATE_WIN_LOSS";

export const FETCH_SUPPORT_REQUEST = "FETCH_SUPPORT_REQUEST";
export const FETCH_SUPPORT_SUCCESS = "FETCH_SUPPORT_SUCCESS";
export const FETCH_SUPPORT_FAILURE = "FETCH_SUPPORT_FAILURE";
export const UPDATE_SUPPORT_REQUEST = "UPDATE_SUPPORT_REQUEST";
export const UPDATE_SUPPORT_SUCCESS = "UPDATE_SUPPORT_SUCCESS";
export const UPDATE_SUPPORT_FAILURE = "UPDATE_SUPPORT_FAILURE";

// cater packages
export const FETCH_CATER_PACKAGE_REQUEST = "FETCH_CATER_PACKAGE_REQUEST";
export const FETCH_CATER_PACKAGE_SUCCESS = "FETCH_CATER_PACKAGE_SUCCESS";
export const FETCH_CATER_PACKAGE_FAILURE = "FETCH_CATER_PACKAGE_FAILURE";

export const ADD_CATER_PACKAGE_REQUEST = "ADD_CATER_PACKAGE_REQUEST";
export const ADD_CATER_PACKAGE_SUCCESS = "ADD_CATER_PACKAGE_SUCCESS";
export const ADD_CATER_PACKAGE_FAILURE = "ADD_CATER_PACKAGE_FAILURE";

export const UPDATE_CATER_PACKAGE_REQUEST = "UPDATE_CATER_PACKAGE_REQUEST";
export const UPDATE_CATER_PACKAGE_SUCCESS = "UPDATE_CATER_PACKAGE_SUCCESS";
export const UPDATE_CATER_PACKAGE_FAILURE = "UPDATE_CATER_PACKAGE_FAILURE";

// cater subscription
export const FETCH_CATER_SUBSCRIPTION_REQUEST =
	"FETCH_CATER_SUBSCRIPTION_REQUEST";
export const FETCH_CATER_SUBSCRIPTION_SUCCESS =
	"FETCH_CATER_SUBSCRIPTION_SUCCESS";
export const FETCH_CATER_SUBSCRIPTION_FAILURE =
	"FETCH_CATER_SUBSCRIPTION_FAILURE";

// FETCH INDIVIDUAL
export const FETCH_COMPANY_SUBSCRIPTION_REQUEST =
	"FETCH_COMPANY_SUBSCRIPTION_REQUEST";
export const FETCH_COMPANY_SUBSCRIPTION_SUCCESS =
	"FETCH_COMPANY_SUBSCRIPTION_SUCCESS,";
export const FETCH_COMPANY_SUBSCRIPTION_FAILURE =
	"	FETCH_COMPANY_SUBSCRIPTION_FAILURE";

export const ADD_CATER_SUBSCRIPTION_REQUEST = "ADD_CATER_SUBSCRIPTION_REQUEST";
export const ADD_CATER_SUBSCRIPTION_SUCCESS = "ADD_CATER_SUBSCRIPTION_SUCCESS";
export const ADD_CATER_SUBSCRIPTION_FAILURE = "ADD_CATER_SUBSCRIPTION_FAILURE";

export const UPDATE_CATER_SUBSCRIPTION_REQUEST =
	"UPDATE_CATER_SUBSCRIPTION_REQUEST";
export const UPDATE_CATER_SUBSCRIPTION_SUCCESS =
	"UPDATE_CATER_SUBSCRIPTION_SUCCESS";
export const UPDATE_CATER_SUBSCRIPTION_FAILURE =
	"UPDATE_CATER_SUBSCRIPTION_FAILURE";

export const DELETE_CATER_SUBSCRIPTION_REQUEST =
	"DELETE_CATER_SUBSCRIPTION_REQUEST";
export const DELETE_CATER_SUBSCRIPTION_SUCCESS =
	"DELETE_CATER_SUBSCRIPTION_SUCCESS";
export const DELETE_CATER_SUBSCRIPTION_FAILURE =
	"UPDATE_CATER_SUBSCRIPTION_FAILURE";
